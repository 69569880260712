<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="group">
      <v-card-title class="display-2">
        <v-row>
          <v-col v-if="event && group" class="pa-0 pl-4 grow">Reports for {{ group.name }} @ {{ event.name }}</v-col>
        </v-row>
      </v-card-title>

      <div v-if="event">
        <v-card-text v-if="status.join_url">
          Direct join link (to share with your team members):
          <v-text-field readonly v-model="status.join_url" hide-details>
            <template v-slot:append>
              <v-dialog v-if="tenant.id == 'cofi'" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn icon color="primary" v-on="on" title="View QR code">
                    <v-icon>fadl fa fa-qrcode</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Join Event QR</v-card-title>
                  <v-card-text>Share this code with the other participants so they can join as well.</v-card-text>
                  <QrCode :data="status.join_url" />
                </v-card>
              </v-dialog>
              <v-btn text color="primary" class="ma-0" @click="$helpers.copyToClipboard(status.join_url, $toast);">Copy</v-btn>
            </template>            
          </v-text-field>
        </v-card-text>

        <v-card-title class="subtitle">Leaderboard Reports</v-card-title>
        <v-card-text>
          Reports are only available for individual leaderboards. Excel reports will only contain data for members of this team.
        </v-card-text>

        <v-data-table
          :headers="[
            { text: 'Name', sortable: true, value: 'name',},
            { text: 'Actions', value: 'actions', sortable: false },
          ]"
          :items="event.races.filter(x => !x.team)"
          :items-per-page="100"
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <v-btn small color="primary" text class="" @click="downloadRaceReport(item.id, 'progress', `${item.id}-progress`)">
              <v-icon small class="mr-2">fa-download</v-icon>
              Results
            </v-btn>
          </template>
        </v-data-table>

        <v-card-title class="subtitle">Member status</v-card-title>
        <v-alert v-if="event.team_max_members && status.members.length >= event.team_max_members" type="info" tile>This team has reached the max team size of {{event.team_max_members}}. No new members may join anymore.</v-alert>
        <v-card-text>
          Use this table to verify the status for each team member.
          <span v-if="event.team_max_members">{{ status.members.length }} out of the maximum allowed {{ event.team_max_members }} members have joined this team.</span>
        </v-card-text>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Status</th>
                <th class="text-left">Member</th>
                <th v-if="hasFullAccess" class="text-left">Code</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in status.members" :key="'act'+idx">
                <td>
                  <v-icon v-if="item.connected" small color="green" class="mr-1">fa-check-circle</v-icon>
                  <v-icon v-if="!item.connected" small color="red" class="mr-1">fa-times-circle</v-icon>
                  <span v-if="!item.connected && !item.id">Not yet joined</span>
                </td>
                <td>
                  {{ item.name }} 
                  <span v-if="item.captain" class="font-weight-bold">(team captain)</span>
                </td>
                <td v-if="hasFullAccess">{{ item.verification_code }} </td>
                <td><v-btn v-if="hasFullAccess" text color="primary" @click="removeMember(item)">Remove</v-btn> </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import QrCode from '@/components/generic/QrCode.vue'
import EventGrid from "@/components/EventGrid.vue";
import EventCard from "@/components/EventCard.vue";
import EventFriendReferral from "@/components/EventFriendReferral.vue";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    QrCode,
    EventGrid,
    EventCard,
    EventFriendReferral,
    RaceResultDetailsDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      groupId: null,
      group: null,
      event: null,
      status: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData(groupId, eventId) {
      this.groupId = groupId;
      this.event = (await eventService.get(eventId)).data;
      this.status = (await profileService.getGroupEventStatus(groupId, eventId)).data;
      this.group = this.status.group;
    },

    async removeMember(member) {
      if (confirm(`Are you sure you want to remove ${member.name} from this group and the results?`)) {
        const response = (await profileService.disconnectGroupAndEvent(this.groupId, member.id, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully removed ${member.name} from this group`);
        await this.getProfile();
      }
    },

    async getProfile() {
      if (this.user) {
        await this.loadData(this.$route.params.id, this.$route.params.eventId);
      }
    },

    async downloadRaceReport(raceId, reportName, filename) {
      try{
        const response = await eventManagerService.exportRaceReport(this.event.id, raceId, reportName, this.group.id);
        this.downloadReportData(response, filename);
      }
      catch {
        this.$helpers.toastResponse(this, { status: "ERROR", msg: "No permissions to view report."});
      }
    },

    async downloadReportData(response, filename) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename.replace(".", "_")
        link.click()
        URL.revokeObjectURL(link.href)
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.group || !this.event ? [] : [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: this.group.name, exact: true, to: { name: 'profileGroupsView', params: { id: this.group.id } } },
        { text: this.event.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    me() {
      return !this.status ? null : this.status.me;
    },

    isAutoTeam() {
      return this.group && (this.group.type == 'AUTO_TEAM');
    },

    isAdminForTeam() {
      return this.isAutoTeam
        ? (this.status.me && this.status.me.captain) || (this.event && this.event.join_select_team && this.group.is_owner)
        : this.group.is_owner || (this.status.me && this.status.me.captain)
        ;
    },

    hasFullAccess() {
      return !this.status ? false : (this.status.event_admin || this.isAdminForTeam);
    },

    profiles() {
      return !this.status || !this.status.members ? [] : this.status.members.filter(x => x.connected && x.id);
    },

    hasSlotSports() {
      return this.slots && this.slots.some(x => x.slot_type);
    },
    hasSlotTimes() {
      return this.slots && this.slots.some(x => x.slot_start || x.slot_end);
    },

    slotsGroupedByRace() {
      return this.slots ? this.slots.reduce((acc, item) => {
        (acc[item.race_id] = acc[item.race_id] || []).push(item)
        return acc
      }, {}) : [];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
.join-url {
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  border: dotted 3px #ccc;
  margin-bottom: 10px;
}
.join-code {
  font-size: 3em;
  letter-spacing: 1em;
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  text-transform: uppercase;
  border: dotted 3px #ccc;
}
</style>

